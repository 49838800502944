v.HorizontalParallax = class HorizontalParallax extends v.ScrollEvents{
    
    
    
    constructor(data = {}){
        
        super();
        
        this.prefix = `${this.prop.v.prefix()}parallax`;

        this.prop = Object.assign(this.prop, {
            selector: `.${this.prefix}`,
            k: .2,
            do: function(){}
        });
        
        for(let value in data){
            this.prop[value] = data[value];
        }

    }



    check(){

        this.scrollLeft = 0;

        if(!super.check()){
            return false;
        }

        let checked = this.checkTypes({
            obj: this.prop,
            name: this.name,
            console: this.prop.console,
            vars: [
                ['k', 'number'],
                ['do', 'function']
            ]
        });

        if(!checked){
            return false;
        }

        return true;

    }



    setHeight(){

        super.setHeight();

        this.width = this.outer.clientWidth;
        if(this.outer === window || this.outer === this.prop.v.document() || this.outer === this.prop.v.body()){
            this.width = this.prop.v.vw()[1];
        }

        this.setScrollTop();
        
        return true;

    }



    setScrollTop(){

        super.setScrollTop();

        if(this.outer === window || this.outer === this.prop.v.document() || this.outer === this.prop.v.body()){
            this.scrollLeft = window.pageXOffset;
        }
        else{
            this.scrollLeft = this.outer.scrollLeft;
        }
        
        return true;

    }



    setEvents(){

        // elements

        for(let i = 0; i < this.elements.length; i++){
            let attribute = this.elements[i].getAttribute(this.data.proceeded);
            if(attribute === null){
                this.elements[i].setAttribute(this.data.proceeded, true);
                if(typeof this.elements[i].translateX == "undefined"){
                    this.elements[i].translateX = 0;
                }
            }
        }

        // outer

        this.setScrollEvent();
        
        return true;

    }



    seek(){

        if(!super.seek()){
            return false;
        }
        
		for(let i = 0; i < this.elements.length; i++){

            let width = this.elements[i].clientWidth,
                bounding = this.elements[i].getBoundingClientRect(),
                boundingLeft = bounding.left - this.elements[i].translateX,
                boundingRight = bounding.right - this.elements[i].translateX,
                offsetLeft = boundingLeft + this.scrollLeft,
                maximum = offsetLeft + width;

            if(boundingLeft < this.width & boundingRight > 0){

                let percent = (this.scrollLeft) / maximum;

                this.prop.do(percent, this.elements[i]);

            }

		}

        return true;

    }

    
    
    init(){

        if(!super.init()){
            return false;
        }

        return {
            getElements: () => this.elements,
            getOuter: () => this.outer,
            seek: this.seek.bind(this),
            add: this.add.bind(this),
            delete: this.delete.bind(this),
            turn: this.turn.bind(this),
            events: () => this.events,
            prop: () => this.prop,
            changeProp: this.changeProp.bind(this)
        };

    }



}